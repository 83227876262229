//DESAROLLADO ASISTIDO POR IA

const cache = new Map(); // Almacenamiento en memoria para datos temporales
const CACHE_TTL = 2 * 60 * 1000; // Tiempo de vida en milisegundos (2 minutos)

function getSizeInKB(value) {
  return new Blob([value]).size / 1024;
}

/**
 * Hashea la URL para usarla como clave en el cache.
 * @param {string} url - La URL a hashear.
 * @param {int} intervalInSeconds - El numero de segundo que genera el mismo hash
 * @returns {string} - Hash simple basado en la URL.
 */
function hashUrl(url, intervalInSeconds = 120) {
  const now = Math.floor(Date.now() / 1000); //Seconds
  const timeSegment = Math.floor(now / intervalInSeconds);
  const dataToEncode = `${url}::${timeSegment}`;
  
  return btoa(dataToEncode); // Usa Base64 como hash básico
}

/**
 * Limpia las entradas expiradas del almacenamiento local.
 */
function cleanLocalStorage() {
  const now = Date.now();
  const keysToRemove = [];

  for (const key in localStorage) {
    if (key.startsWith("cache_")) {
      const item = JSON.parse(localStorage.getItem(key));
      if (item.expiry < now) {
        keysToRemove.push(key);
      }
    }
  }

  keysToRemove.forEach((key) => localStorage.removeItem(key));
}

/**
 * Recupera datos de una URL, usando un sistema de caché en memoria y `localStorage`.
 * @param {string} url - La URL del recurso a obtener.
 * @param {number} [ttl=CACHE_TTL] - Tiempo de vida del caché en milisegundos.
 * @returns {Promise<any>} - Promesa que resuelve con los datos.
 */
export default async function fetchData(url, parameters, ttl = CACHE_TTL) {
  const hashedUrl = `cache_${hashUrl(url)}`;
  // Limpiar entradas expiradas en localStorage
  cleanLocalStorage();

  // Verificar si ya hay datos en memoria
  if (cache.has(hashedUrl)) {
    const { data: cachedData, expiry } = cache.get(hashedUrl);
    if (expiry > Date.now()) {
      return Promise.resolve(
        new Response(JSON.stringify(cachedData), { status: 200 })
      );
    }
    // Eliminar entrada expirada
    cache.delete(hashedUrl);
  }

  // Verificar si ya hay datos en localStorage
  const localData = localStorage.getItem(hashedUrl);
  if (localData) {
    const { data: localCachedData, expiry } = JSON.parse(localData);
    if (expiry > Date.now()) {
      cache.set(hashedUrl, { data: localCachedData, expiry });
      return Promise.resolve(
        new Response(JSON.stringify(localCachedData), { status: 200 })
      );
    }
    // Eliminar entrada expirada
    localStorage.removeItem(hashedUrl);
  }

  // Si no se encuentra en cache ni en localStorage, hacer la solicitud al servidor
  const response = await fetch(url, parameters);
  if (!response.ok) {
    throw new Error(`Error fetching data: ${response.statusText}`);
  }

  const data = await response.json();
  const expiry = Date.now() + ttl;

  /* ALLOWS TO KNOW KB IN TOTAL AND FOR EACH DATA BLOCK
  let totalSize = 0;
  for (let key in localStorage) {
    if (localStorage.hasOwnProperty(key)) {
      totalSize += localStorage[key].length;
    }
  }
  MEDICIÓN PESO EN KB
  const myData = { data, expiry };
  const jsonData = JSON.stringify(myData);
  console.log("Tamaño en KB:", getSizeInKB(jsonData).toFixed(2), "KB");
  console.log(`Tamaño total de localStorage: ${totalSize / 1024} KB`); // En KB
  */

  // IF DATA IS SMALLER THAN 1000 KB STORES IN MEMORY AND LOCAL STORAGE
  if (getSizeInKB(JSON.stringify({ data, expiry })).toFixed(2) < 1000) {
    cache.set(hashedUrl, { data, expiry });
    localStorage.setItem(hashedUrl, JSON.stringify({ data, expiry }));
  }

  return new Response(JSON.stringify(data), { status: 200 });
}
